import React from "react";
import Image from "next/image";

import { ReadyToStartContainer, Content, Icons, Item } from "./styles";

import akaliImage from "public/assets/img/ready-to-start/akali-bg.webp";
import valorantImage from "public/assets/img/calculator/valorant-white.svg";
import wildRiftImage from "public/assets/img/calculator/wr-white.svg";
import lolImage from "public/assets/img/calculator/lol-white.svg";

export const ReadyToStartSection = () => {
  return (
    <ReadyToStartContainer>
      <picture>
        <Image src={akaliImage} alt="Akali" priority={false} />
      </picture>

      <Content>
        <h2>
          Pronto para começar?
          <br /> <span className="text-border">Compre agora!</span>
        </h2>
        <Icons>
          <Item href="/contratar/elojob/league-of-legends">
            <Image
              src={lolImage}
              alt="ícone League of Legends"
              width={100}
              height={100}
            />
            {/* <span>BOOST LOL</span> */}
          </Item>
          <Item href="/contratar/elojob/wild-rift">
            <Image
              src={wildRiftImage}
              alt="ícone wild rift"
              width={100}
              height={100}
            />
            {/* <span>BOOST WILD RIFT</span> */}
          </Item>
          <Item href="/contratar/elojob/valorant">
            <Image
              src={valorantImage}
              alt="ícone valorant"
              width={100}
              height={100}
            />

            {/* <span>BOOST VALORANT</span> */}
          </Item>
        </Icons>
      </Content>
    </ReadyToStartContainer>
  );
};
