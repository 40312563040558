import tokens from "@/tokens";
import styled from "styled-components";
import { SectionContainer } from "../SectionContainer";
import Link from "next/link";

export const ReadyToStartContainer = styled(SectionContainer)`
  margin: 0;
  padding:0;
  color: #fff;
  background-size: cover !important;
  position: relative;
  z-index: 4;
  background: linear-gradient(
      to right,
      rgba(195, 77, 1, 0.85),
      rgba(195, 77, 1, 0.85)
    ),
    url(/assets/img/ready-to-start/home_chat_bg.webp) center bottom;
  max-width: 100%;


 
  picture {

   position: relative;
    width: 100%;
    max-width:50%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;

    @media screen and (max-width: ${tokens.breakpoints.s}) {
      display:none;
       
  }
    
    img{
      vertical-align: start;
      z-index: 3;
      max-width: 100%;
      width: auto;
      height: 100%;


    }
  
  }
`;

 

export const Content = styled.div`
  width: 100%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  @media screen and (max-width: ${tokens.breakpoints.s}) {
    max-width: 100%;
    align-items: center;
    text-align:center;
    padding:2rem 0;

  }


  @media screen and (max-width: ${tokens.breakpoints.s}) {
    margin-left: 16px;
    max-width: 100%;
  }

    
  h2 ,h2 span{
    margin-top: clamp(25px, 3vw, 40px);
    font-weight:900;
    letter-spacing: 0px;
    text-transform: uppercase;
    font-size: clamp(calc( (3rem) / 1.5), 5vw, 3rem);
    margin-bottom:2rem;

     span.text-border {
      -webkit-text-stroke: 2px currentColor;
      -webkit-text-fill-color: transparent;
     }
  }
 


`;

export const Icons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 48px;
  flex-wrap: wrap;
  justify-content: center;

`;

export const Item = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position:relative;
  padding:1rem;

  &::before {
    content: "";
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient( to right, rgba(255,255,255,0.1), rgba(138,204,255,0.3), rgba(255,255,255,0.1) );
    opacity: 0;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
}

&:hover::before{
  opacity:1;
}

  span {
    font-weight: 700;
  }

  @media screen and (max-width: ${tokens.breakpoints.l}) {
    margin-bottom:2rem;
    
    img{
      width:70px !important;
      height: 70px !important;
    }
  }
      
  @media screen and (max-width: ${tokens.breakpoints.m}) {
    margin-bottom:2rem;

    img{
      width:50px !important;
      height: 50px !important;
    }
  }
`;
